import styled from "styled-components";

export const ShowChatWrapper = styled.div`
  position: absolute;
  left: 80px;
  top: 50px;

  width: 555px;
  height: 225px;
  overflow: hidden;
`;
