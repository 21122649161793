import styled from "styled-components";

export const ShowChatWrapper = styled.div`
  position: absolute;

  left: 0;
  top: 860px;

  height: 120px;
  width: 1520px;
  overflow: hidden;
  padding: 10px 20px;
`;
