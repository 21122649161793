import styled from "styled-components";

export const SponsorWrapper = styled.div`
  position: absolute;
  top: 885px;
  left: 0px;
  width: 610px;
  height: 110px;

  border-bottom: 5px solid ${props => props.theme.colors.accent2};
`;
