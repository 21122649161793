/* istanbul ignore file */
export const theme = {
  colors: {
    bg1: "rgba(0, 0, 0, 0.98)",
    accent1: "#31aafd",
    accent2: "#1c1b29",
    accent3: "#1c1b29"
  }
};

// export const theme = {
//   colors: {
//     bg1: "rgba(0, 0, 0, 0.98)",
//     accent1: "#430e52",
//     accent2: "#1c1b29",
//     accent3: "#1c1b29"
//   }
// };
